// React and other libraries
import { useState } from 'react';
import sortBy from 'lodash/sortBy';
import update from 'immutability-helper';
import { useDeepCompareEffect } from 'react-use';
import { Box } from '@mui/material';

import { UNDERSTANDING_OF_ENTITY } from '@ais/constants';
import {
  getRowClassName,
  scotabdsColumns,
  scotabdsTableStyles,
  CLANestedDataGrid,
} from '@ais/components';

import './styles.css';
import {ConcurrentInternalControlTextbox} from '../ConcurrentInternalControlTextbox/ConcurrentInternalControlTextbox';

const UOEScotabdsConstants = UNDERSTANDING_OF_ENTITY.SCOTABDS;

const renderCustomCells = (columnData) => {
  const cellRendering = {
    [UOEScotabdsConstants.COMMENTS_FIELD]: {
      ...columnData,
    },
    default: columnData
  };

  return cellRendering[columnData.field] || cellRendering.default;
};

const getStringedAccountBalanceNames = (accountBalanceTypeList) => {
  const accBalanceNamesArray = accountBalanceTypeList?.map((abt) => abt.AccountBalanceName) || [];
  const stringedAccBalanceNames = accBalanceNamesArray.join(', ');

  // attach parentheses at both ends when not empty string
  return stringedAccBalanceNames === '' ? stringedAccBalanceNames : `(${stringedAccBalanceNames})`;
};

const UOEScotabdsTable = (props) => {

  const {
    auditAreaIndex,
    auditAreaId,
    scotabds = [],
    currentInternalControlData,
    saveProjectScopeAuditAreaFields,
    latestInternalControlData
  } = props;

  const UOEInfoProcessingConstants = UNDERSTANDING_OF_ENTITY.INFORMATION_PROCESSING;

  const [scotabdDataRows, setScotabdDataRows] = useState([]);
  const [modifiedColumns, setModifiedColumns] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  const handleAdditionalCollapseTrigger = (newIds) => {
    setExpandedRows(newIds);
  };

  const findIndexes = (auditAreaId, scotabdId) => {
    const foundAuditAreaIndex = currentInternalControlData?.ProjectScopeAuditArea?.findIndex(
      (item) => item.AuditAreaId === auditAreaId
    );

    const foundScotabdIndex = scotabds.findIndex((item) => item.ProjectScopeAuditAreaSCOTABDId === scotabdId);

    return [foundAuditAreaIndex, foundScotabdIndex];
  };

  const handleBlur = (event, auditAreaId, scotabdId, key) => {

    const [foundAuditAreaIndex, foundScotabdIndex] = findIndexes(auditAreaId, scotabdId)

    // Check for nullish values (null or undefined) first
    if (
      foundAuditAreaIndex == null ||
      foundAuditAreaIndex === -1 ||
      foundScotabdIndex == null ||
      foundScotabdIndex === -1
    ) {
      return;
    }

    const currentCollapsedRecord = currentInternalControlData
      ?.ProjectScopeAuditArea[foundAuditAreaIndex]
      .ProjectScopeAuditAreaSCOTABDS[foundScotabdIndex]

    const currentInputValue = currentCollapsedRecord[key]

    if (currentInputValue === event.target.value) return;

    const otherCommentKey = (key === UNDERSTANDING_OF_ENTITY.KEYS.ICINFOCOMMENT)
      ? UNDERSTANDING_OF_ENTITY.KEYS.ICCOMMENT
      : UNDERSTANDING_OF_ENTITY.KEYS.ICINFOCOMMENT

    const payload = {
      ProjectScopeAuditAreaSCOTABDId: currentCollapsedRecord?.ProjectScopeAuditAreaSCOTABDId,
      [key]: (currentCollapsedRecord[key] === null) ? "" : event.target.value,
      [otherCommentKey]: (currentCollapsedRecord[otherCommentKey] === null) ? "" : currentCollapsedRecord[otherCommentKey]
    }
    const newInternalControlData = update(latestInternalControlData.current, {
      ProjectScopeAuditArea: {
        [foundAuditAreaIndex]: {
          ProjectScopeAuditAreaSCOTABDS: {
            [foundScotabdIndex]: {
              $merge: {
                [key]: event.target.value
              }
            }
          }
        }
      }
    });

    saveProjectScopeAuditAreaFields(payload, newInternalControlData)
  };

  const updateScotabdsData = (scotabds) => {
    return scotabds.map((scotabd, index) => ({
      ...scotabd,
      id: `AuditArea-${auditAreaIndex}-Scotabd-${index}`,
      index,
      stringAccountBalanceTypeList: getStringedAccountBalanceNames(scotabd.AccountBalanceTypeList),
      ExpandedPanel: (
        <Box sx={{ ml: '60px', my: '20px' }}>
          <ConcurrentInternalControlTextbox
            label={UOEInfoProcessingConstants.LABEL}
            placeholder={UOEInfoProcessingConstants.PLACEHOLDER}
            value={scotabd.ICInfoProcessingAndControlActivity || ''}
            maxLength={4096}
            onBlur={(e) => handleBlur(e, auditAreaId, scotabd.ProjectScopeAuditAreaSCOTABDId, UNDERSTANDING_OF_ENTITY.KEYS.ICINFOCOMMENT)}
            customFormObjectId={`AuditArea-${auditAreaIndex}-Scotabd-${index}`}
          />
        </Box>
      )
    }));
  };

  const sortScotabdsData = (scotabds) => {
    return sortBy(scotabds, UOEScotabdsConstants.DISPLAY_ORDER);
  };

  useDeepCompareEffect(() => { 
    if (scotabds && scotabds.length !== 0) {
      const sortedScotabdsData = sortScotabdsData(scotabds);
      const formattedScotabdsData = updateScotabdsData(sortedScotabdsData);

      setScotabdDataRows(formattedScotabdsData);
      const newModifiedColumn = scotabdsColumns
        .filter(data => data.field !== UNDERSTANDING_OF_ENTITY.SCOTABDS.COMMENTS_FIELD && data.field !== UNDERSTANDING_OF_ENTITY.SCOTABDS.KEY_CONTROLS_FIELD)
        .map((columnData) =>
          renderCustomCells(columnData)
        );
      setModifiedColumns(newModifiedColumn);
    }
  }, [scotabds]);

  return (
    <CLANestedDataGrid
      sx={scotabdsTableStyles}
      columns={modifiedColumns}
      rows={scotabdDataRows}
      expandedRow={expandedRows}
      headerHeight={62}
      getRowClassName={(params) => getRowClassName(params, scotabdDataRows, expandedRows, 2, auditAreaIndex)}
      handleAdditionalCollapseTrigger={handleAdditionalCollapseTrigger}
      hideFooter={true}
    />
  );
};

export default UOEScotabdsTable;