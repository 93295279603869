import { LogLevel, PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import jwt from "jwt-decode"

import { clientScopes, reportScopes } from './scopes';

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
        authority: process.env.REACT_APP_AZURE_AD_AUTHORITY,
        redirectUri: '/',
        postLogoutRedirectUri: '/'
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: window.navigator.userAgent.indexOf('MSIE ') > 0
            || window.navigator.userAgent.indexOf('Trident/') > 0
            || window.navigator.userAgent.indexOf('Edge/') > 0
            || window.navigator.userAgent.indexOf('Firefox') > 0
    },
    system: {
        loggerOptions: {
            logLevel: LogLevel.Info,
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        // console.error(message);
                        return;
                    case LogLevel.Info:
                        // console.info(message);
                        return;
                    case LogLevel.Verbose:
                        // console.verbose(message);
                        return;
                    case LogLevel.Warning:
                        // console.warn(message);
                        return;
                    default:
                        return;
                    }
            }
        }
    }
}

export const msalInstance = new PublicClientApplication(msalConfig)


export class NotLoggedInError extends Error {
    constructor(message) {
        super(message);
        this.name = 'NotLoggedInError';
    }
}

export class InvalidScopeError extends Error {
    constructor(message) {
        super(message);
        this.name = 'InvalidScopeError';
    }
}

// indicates if a user is authenticated or not
export const authenticated = () => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();
    return (!!activeAccount || accounts.length > 0);
}

export const getAccount = () => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();
    if (!!activeAccount) {
        return activeAccount;
    }
    if (accounts.length > 0) {
        return accounts[0];
    }
    return null;
}

export const getAccessToken = async (instance, request) => {
    let token;
    // Making a request with an empty scope array will just try to use the most recently acquired/refreshed token
    // which is very unlikely to always be the right one (e.g. it would try to use a token for the Project API even
    // if you were requesting a token to use with the Graph API).
    if (!request.scopes || request.scopes.length === 0) {
        throw new InvalidScopeError('You must provide a non-empty scopes array in the request.');
    }
    try {
        const authResult = await instance.acquireTokenSilent(request);
        token = authResult.accessToken;
    }
    catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            const authResult = await instance.acquireTokenPopup({
                scopes: request.scopes
            });
            token = authResult.accessToken;
        }
        else {
            throw error;
        }
    }
    return token;
};

// injects developer-friendly tokens to decouple this solution from Azure AD
// this is only available in local development environments
export let injectLocalDevelopmentTokens = () => {
    console.warn('This is not intended to be used for production builds!');
}

export let enableLocalDevelopmentTokens = () =>
  (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') 
    && process.env.REACT_APP_AZURE_AD_CLIENT_ID === '00000000-0000-0000-0000-000000000000';

if (enableLocalDevelopmentTokens()) {
  injectLocalDevelopmentTokens = () => {
    // compile scopes for all local APIs
    const scopes = clientScopes.concat(reportScopes);

    // snapshot expiry time for 7 days from now
    const expireTime = Date.now() + 604800000;

    // inject tokens directly into MSAL cache
    const msalTokenCache = msalInstance.getTokenCache();
    msalTokenCache.loadExternalTokens(
      {
        scopes: scopes,
        authority: process.env.REACT_APP_AZURE_AD_AUTHORITY
      },
      {
        token_type: 'Bearer',
        scopes: scopes.join(' '),
        // actually interpreted as "expires on" in MSALs implementation
        expires_in: expireTime,
        id_token: jwt.sign(
          {
            name: 'CLA Developer',
            given_name: 'CLA',
            family_name: 'Developer',
            email: 'developer@claconnect.invalid'
          },
          '00000000-0000-0000-0000-000000000000'
        ),
        access_token: jwt.sign(
          {
            oid: '00000000-0000-0000-0000-000000000000',
            scp: scopes.join(' ')
          },
          '00000000-0000-0000-0000-000000000000'
        )
      },
      {
        clientInfo: 'localhost',
        extendedExpiresOn: expireTime
      }
    );
  }
}