import { useEffect } from 'react';
import { isEqual } from "lodash";
import { useForm } from "react-hook-form";
import { json } from 'react-router-dom';

import { Form } from "@ais/palette";

import { ProjectFormSection } from "@components/ProjectForm";
import { useProjectFormContext } from "@contexts";
import { useFormAnswersMutation } from "@services";
import { useIdle } from 'react-use';
import { toast } from 'react-toastify';
import { WAITING_TIME } from "@ais/constants";
export const ProjectForm = ({ schema, answers }) => {
    const { projectId, projectFormId, projectForm } = useProjectFormContext()
    const { mutate, error } = useFormAnswersMutation(projectId, projectFormId)
    const isIdle = useIdle(WAITING_TIME.MINS_13);
    const formMethods = useForm({
        defaultValues: {},
        values: answers
    })

    const getIdsWithUpdatedValues = (obj, source) =>
    Object.keys(source).filter(
        (key) =>
            !obj.hasOwnProperty(key) ||
            (obj.hasOwnProperty(key) && !isEqual(obj[key], source[key]))
    )

    const handleSubmit = (formValues) => {
        const updatedValues = getIdsWithUpdatedValues(answers, formValues)
        updatedValues.forEach((updatedValue) => {
            const updatedAnswer = formValues[updatedValue]
            if (updatedAnswer === null || updatedAnswer === undefined) {
                return
            }
            const payload = {
                projectId,
                projectUnitIds: projectForm.units.map((unit) => unit.ProjectUnitID),
                questionId: updatedValue,
                answer: updatedAnswer
            }
            mutate(payload)
        })
    }

    useEffect(() => {
        if (isIdle) {
            toast.error('Reconnect now', {
                autoClose: false,
            });
            const delay = setTimeout(() => {
                toast.dismiss();
            }, WAITING_TIME.MINS_2);

            return () => clearTimeout(delay)
        }
    }, [isIdle])

    useEffect(() => {
        if (!error || !error.response) return;
        const { data, status } = error.response;

        throw json(data, status);
    }, [error])

    return (
        <Form form={formMethods} style={{ width: "100%" }}>
            <ProjectFormSection handleSubmit={formMethods.handleSubmit(handleSubmit)} schema={schema} />
        </Form>
    )
}

export default ProjectForm
