import React from "react"
import { useRouteError, isRouteErrorResponse, useParams } from "react-router-dom"
import { ClaLogoWhite } from "@ais/assets"
import { AUTHORIZATION } from "@ais/constants"
import { Flex } from "@ais/palette"

import { Unauthorized } from "@components/ProjectForm"
import { NotLoggedInError } from "../../../utilities/authentication"
import styles from "./NoAccessErrorBoundary.module.css"
import { useTitle } from "../../../hooks/useTitle"

const NoAccessErrorBoundary = () => {
    const { projectFormId } = useParams();
    const error = useRouteError()
    console.error("Error encountered: ", error)
    let title = "Something went wrong"
    if(isRouteErrorResponse(error) || error.status === 403) {
        if(error.status === 401 || error.status === 403) {
            title = "No access"
        }
    }
    if(projectFormId){
        title = "Notice"
    }
    useTitle(title)

    if(isRouteErrorResponse(error) || error.status === 403) {
        console.error("Error after isRouteErrorResponse(error) line 25: ", error)
        if(error.status === 401) {
            return (
                <div className={styles["no-access-error-boundary"]}>
                    <svg className={styles["no-access-error-boundary__background"]} width="1431" height="1191" viewBox="0 0 1431 1191" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0 0H699.08L1430.4 1897H0V0Z" fill="#2E334E"/>
                    </svg>
                    <Flex direction="column" className={styles["no-access-error-boundary__content"]}>
                        <img src={ClaLogoWhite} className={styles["no-access-error-boundary__logo"]} />
                        <h1 className={styles["no-access-error-boundary__title"]}>Notice</h1>
                        <hr className={styles["no-access-error-boundary__divider"]} />
                        <p className={styles["no-access-error-boundary__subtitle"]}>{ AUTHORIZATION.EN.NO_ACCESS_MESSAGE }</p>
                    </Flex>
                </div>
            )
        }
        if(error.status === 403) {
            return <Unauthorized />
        }
    }

    if(error instanceof NotLoggedInError){
        return (<></>)
    }

    return (
        <div className={styles["no-access-error-boundary"]}>
            <svg className={styles["no-access-error-boundary__background"]} width="1431" height="1191" viewBox="0 0 1431 1191" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 0H699.08L1430.4 1897H0V0Z" fill="#2E334E"/>
            </svg>
            <Flex direction="column" className={styles["no-access-error-boundary__content"]}>
                <img src={ClaLogoWhite} className={styles["no-access-error-boundary__logo"]} />
                <h1 className={styles["no-access-error-boundary__title"]}>{title}</h1>
                <hr className={styles["no-access-error-boundary__divider"]} />
                {
                    !!projectFormId ? 
                        <div className={styles["no-access-error-boundary__subtitle"]}>
                        { AUTHORIZATION.EN.NO_PROJECT_FORM_LOCATED }
                        </div>
                    :
                        <details style={{ whiteSpace: "pre-wrap" }} className={styles["no-access-error-boundary__subtitle"]}> 
                            { error.toString() }
                        </details>
                }
            </Flex>
        </div>
    )
}

export default NoAccessErrorBoundary
