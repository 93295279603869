import React, {useEffect} from "react"
import {
    createRoutesFromElements,
    createBrowserRouter,
    useLoaderData,
    Await,
    Route,
    RouterProvider,
    Navigate,
    Outlet,
} from "react-router-dom"
import { useMsal } from "@azure/msal-react"
import { QueryClient } from "@tanstack/react-query"
import loadable from "@loadable/component"
import { CLABackDrop } from '@ais/components';
import { AppTypeProvider } from '@ais/providers'

import { ProjectFormContextProvider } from "@contexts";
import { formScopes } from "../utilities/scopes.js";
import { AppLayout } from '@layouts';
import { AppProviders, ProjectFormConcurrencyProvider } from "@providers";
import { sharedFormsLoader, projectLoader } from '@services';
import { SocketProvider } from "@ais/providers";
import NoAccessErrorBoundary from "../components/Common/NoAccessErrorBoundary/NoAccessErrorBoundary";

const Dashboard = loadable(() => import("@views/Dashboard"));
const Todos = loadable(() => import("@views/Todos/Todos"));
const ProjectForm = loadable(() => import("@views/ProjectForm/ProjectFormPage"))

const wsBaseUrl = process.env.REACT_APP_API_EXT_FORM_WEBSOCKET;

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        }
    }
})

const AuthContainer = ({ children }) => {
    const loaderData = useLoaderData();

    return (
        <div>
            <React.Suspense fallback={<CLABackDrop isOpen={true} />}>
                <Await resolve={loaderData}>
                    { children }
                </Await>
            </React.Suspense>
        </div>
    )
}

export const RedirectClientHQ = () => {
    window.location.replace(process.env.REACT_APP_CLIENTHQ_TODOS_URL);
    return <>Loading ...</>;
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            path="/"
            id="shared_forms"
            errorElement={<NoAccessErrorBoundary />}
            element={<Outlet />}
        >
            <Route path="/dashboard" element={<AppLayout />} >
                <Route index element={<Dashboard />} />
            </Route>
            <Route
                id="todos"
                path="/todos"
                element={<AppLayout />}
                errorElement={<NoAccessErrorBoundary />}
            >
                <Route index element={<RedirectClientHQ/>} />
                <Route
                    id="project-form"
                    path="project/:projectId/form/:projectFormId"
                    element={
                        <SocketProvider url={wsBaseUrl}>
                            <ProjectFormContextProvider>
                                <ProjectFormConcurrencyProvider>
                                    <ProjectForm />
                                </ProjectFormConcurrencyProvider>
                            </ProjectFormContextProvider>
                        </SocketProvider>
                    }
                    loader={projectLoader(queryClient)}
                />
            </Route>
        </Route>
    )
)

export const AppRoutes = () => {
    const { accounts, instance } = useMsal();

    useEffect(() => {
        const getTokenFromMsalInstance = async () => {
            const request = { scopes: formScopes, account: accounts[0] };
            const authResult = await instance.acquireTokenSilent(request);
            accounts[0].idTokenClaims.accessToken = authResult.accessToken;
        }

        getTokenFromMsalInstance();
    }, [])

    return (
        <AppProviders>
            <AppTypeProvider isPortalApp={false}>
                <RouterProvider router={router} />
            </AppTypeProvider>
        </AppProviders>
    )
}