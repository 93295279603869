import { useState, useMemo, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import HelpIcon from "@mui/icons-material/Help"
import { Collapse, IconButton } from "@mui/material"
import { sanitize } from "dompurify"
import { useFormContext, useWatch} from "react-hook-form"

import { CollapseIconComponent, ExpandIconComponent } from "@ais/assets";
import { CLATooltip } from "@ais/components"
import { RULES } from '@ais/constants'

import { useProjectFormContext } from "@contexts";

import styles from "./FormSection.module.css"

const useSectionRules = (sectionRules) => {
    const { projectForm } = useProjectFormContext()
    const { getValues, control } = useFormContext() 
    const watchedSection = useWatch({ control: control, name: sectionRules?.criterias?.map(c => c.questionId) ?? [] });
    const methodologyIndustries = useMemo(() => {
        let mi = projectForm.methodologyIndustries
        if (!mi) {
            mi = []
        }
        mi = mi.map((industry) => industry.MethodologyIndustryId)
        return mi
    }, [projectForm])

    const results = useMemo(() => {
        const filtered = []
        sectionRules.criterias.forEach((criteria) => {
            const { criteriaType, questionId, any, isEqual, value } = criteria;
            if (criteriaType === RULES.CRITERIA_TYPES.QUESTION) {
                const formikValue = getValues(questionId);
                const formValue = Array.isArray(formikValue)
                    ? formikValue.filter(
                        (value) => value !== undefined && value !== ''
                    )
                    : [formikValue].filter(
                        (value) => value !== undefined && value !== ''
                    );
                if (any) {
                    if (formValue.length) {
                        filtered.push(true);
                    }
                    else {
                        filtered.push(false);
                    }
                }
                else {
                    if (isEqual === RULES.EQUALITY.IS)
                        filtered.push(
                            formValue.length === value.length &&
                            formValue.every((_formValue) => value.includes(_formValue))
                        );
                    else if (isEqual === RULES.EQUALITY.IS_NOT)
                        filtered.push(
                            !formValue.every((_formValue) => value.includes(_formValue))
                        );
                    else if (isEqual === RULES.EQUALITY.ANY)
                        filtered.push(
                            formValue.some((_formValue) => value.includes(_formValue))
                        );
                }
            }
            else if (criteriaType === RULES.CRITERIA_TYPES.INDUSTRY) {
                if (isEqual === RULES.EQUALITY.IS) {
                    filtered.push(
                        value.every((mi) => methodologyIndustries.includes(mi))
                    );
                }
                else if (isEqual === RULES.EQUALITY.IS_NOT) {
                    filtered.push(
                        !methodologyIndustries.some((mi) => value.includes(mi))
                    );
                }
                else if (isEqual === RULES.EQUALITY.ANY) {
                    filtered.push(
                        methodologyIndustries.some((mi) => value.includes(mi))
                    );
                }
            }
        })
        return filtered
    }, [sectionRules, methodologyIndustries, watchedSection, getValues])

    const hidden = useMemo(() => {
        if (!results) {
            return false
        }
        if (results.length < 1) {
            return false
        }
        let anyResults = false
        if (sectionRules.match === RULES.MATCH.ANY) {
            anyResults = results.some((result) => result === true)
        }
        let allResults = false
        if (sectionRules.match === RULES.MATCH.ALL) {
            allResults = results.every((result) => result === true)
        }
        if (sectionRules.match === RULES.MATCH.ANY) {
            if (sectionRules.behavior === RULES.BEHAVIOR.SHOW) {
                if (anyResults) {
                    return false
                }
                return true
            }
            else if (sectionRules.behavior === RULES.BEHAVIOR.HIDE) {
                if (anyResults) {
                    return false
                }
                return true
            }
        }
        else if (sectionRules.match === RULES.MATCH.ALL) {
            if (sectionRules.behavior === RULES.BEHAVIOR.SHOW) {
                if (allResults) {
                    return false
                }
                return true
            }
            else if (sectionRules.behavior === RULES.BEHAVIOR.HIDE) {
                if (allResults) {
                    return false
                }
                return true
            }
        }
        return false
    }, [results, sectionRules])


    return { hidden, results }
}
const SECTION_TYPES = [
    'SECTION',
    'CUSTOM',
    'PROCEDURE',
    'SEMICUSTOM'
];

export const useScroll = () => {
    const elRef = useRef(null);
    const executeScroll = () => elRef.current.scrollIntoView();

    return [executeScroll, elRef];
};

export const FormSection = ({ section, children }) => {
    const { hidden } = useSectionRules(section.rules)
    const [expanded, setExpanded] = useState(!section.showCollapsed)
    const { search } = useLocation()
    const [executeScroll, elRef] = useScroll()
    const searchParams = new URLSearchParams(search)
    const questionIds = searchParams.get("questionIds")
    const sectionId = searchParams.get("sectionId")
    const sectionType = section ? (section.sectionType ? section.sectionType : section.type) : null
    const hasSingleQuestion = !questionIds || questionIds.split(',').length <= 1
    const shouldScrollToSection = !sectionId ? false : (section.id.toLowerCase().trim() === sectionId.toLowerCase().trim() && hasSingleQuestion)
    const sectionCSS = sectionType === 'SECTION_BLANK' ? styles['vf-section-blank'] : ''

    useEffect(() => {
        if (shouldScrollToSection) {
            executeScroll()
        }
    }, [])

    return (
        <div className={styles["vf-section-wrapper"]} style={{ display: hidden ? 'none' : 'flex' }}>
            <div ref={elRef} className={`${styles['vf-section']} ${sectionCSS}`}>
                {(SECTION_TYPES.includes(sectionType)) && (
                    <div className={styles['section-header-wrapper']}>
                        <IconButton onClick={() => setExpanded((oldState) => !oldState)}>
                            {expanded ? <ExpandIconComponent /> : <CollapseIconComponent />}
                        </IconButton>
                        <div className={styles["section-header"]}>
                            {section.title}
                            {section.tooltip && (
                                <CLATooltip
                                    title={
                                        <div
                                            className="ql-editor"
                                            dangerouslySetInnerHTML={{
                                                __html: sanitize(section.tooltip, {
                                                    ADD_ATTR: ['target'],
                                                }),
                                            }}
                                        >
                                        </div>
                                    }
                                    placement="bottom"
                                >
                                    <HelpIcon style={{ fontSize: '18px' }} />
                                </CLATooltip>
                            )}
                        </div>
                    </div>
                )}
                {section.description && (
                    <div style={{ fontSize: '14px', color: '#595959' }}>
                        <div
                            className="ql-editor"
                            dangerouslySetInnerHTML={{
                                __html: sanitize(section.description, {
                                    ADD_ATTR: ['target'],
                                }),
                            }}
                        />
                    </div>
                )}
                <Collapse in={expanded} collapsedSize={0}>
                    <div className={styles['vf-section-inner']}>
                        {children}
                    </div>
                </Collapse>
            </div>
        </div >
    )
}

export default FormSection
