import { useState, useEffect } from 'react'
import { useIdle } from 'react-use';

import {InternalControlTextbox} from "@ais/components";
import {WAITING_TIME } from "@ais/constants";

import {ConcurrentLockTooltip} from '../../ConcurrentLockTooltip/ConcurrentLockTooltip';
import {useProjectFormConcurrentLocking} from '@hooks';

export const ConcurrentInternalControlTextbox = ({
  label,
  placeholder,
  maxLength = 4096,
  onBlur,
  onFocus,
  value,
  customFormObjectId,
  onConcurrentValueChange,
  ...props
}) => {

  const [content, setContent] = useState(value)

  useEffect(() => {
    setContent(value);
  }, [value]);

  const {
    lockedField,
    lockingUser,
    emitLockEvent,
    emitUnlockEvent,
    emitResetEvent,
    concurrentValue
  } = useProjectFormConcurrentLocking(
    null,
    {
      customFormObjectId
    }
  );

  const isIdle = useIdle(WAITING_TIME.MINS_13);

  useEffect(() => {
    if (typeof concurrentValue?.value === 'string') { 
      onConcurrentValueChange && onConcurrentValueChange(concurrentValue?.value)
    }
  }, [concurrentValue])

  const handleBlur = (e) => {
    if(typeof onBlur === 'function') onBlur(e);
    emitUnlockEvent({ value: e.target.value });
    emitResetEvent();
}

  const handleFocus = (e) => {
    if(typeof onFocus === 'function') onFocus(e);

    setTimeout(() => {
        emitLockEvent({ value: e.target.value })
    }, 400)
  }

  const handleOnChange = (event) => {
    setContent(event.target.value)
  }

  useEffect(() => {
    if (isIdle) {
      const delay = setTimeout(() => {
        if (lockedField === customFormObjectId) {
          emitUnlockEvent({value: null}, true);
          emitResetEvent();
        }
      }, WAITING_TIME.MINS_2);

      return () => clearTimeout(delay)
    }
  }, [isIdle, lockedField, customFormObjectId])

  return (
    <ConcurrentLockTooltip lockingUser={lockingUser}>
      <InternalControlTextbox
        label={label}
        placeholder={placeholder}
        value={content}
        maxLength={maxLength}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleOnChange}
        {...props}
      />
    </ConcurrentLockTooltip>
  )
}

export default ConcurrentInternalControlTextbox;
